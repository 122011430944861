var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suggestions"},[_c('transition',{attrs:{"name":"fade-slide"}},[(_vm.shouldShow)?_c('div',{staticClass:"suggestions__box"},[_c('ul',{staticClass:"suggestions__list"},_vm._l((_vm.suggestions),function(suggestion,index){return _c('li',{key:suggestion.id,staticClass:"suggestions__item"},[_c('router-link',{staticClass:"suggestions__product",attrs:{"to":{
              query: {
                mode: 'details',
                productId: suggestion.productId,
                variantId: suggestion.id,
              },
            }},nativeOn:{"click":function($event){return _vm.trackProductClick(suggestion, index)}}},[(
                suggestion.images &&
                  suggestion.images[0] &&
                  suggestion.images[0].thumbnail
              )?_c('div',{staticClass:"suggestions__image-container"},[_c('img',{staticClass:"suggestions__image",attrs:{"src":suggestion.images[0].thumbnail.src,"alt":suggestion.images[0].thumbnail.alt,"decoding":"async","loading":"lazy"}})]):_vm._e(),_c('div',{staticClass:"suggestions__text"},[_c('Stack',{attrs:{"quarter":true}},[_c('h1',{staticClass:"suggestions__title"},[_vm._v(" "+_vm._s(suggestion.productName)+" - "+_vm._s(suggestion.variantName)+" ")])]),_c('Stack',{attrs:{"quarter":true}},[_c('InformationList',{attrs:{"information":suggestion}})],1)],1)])],1)}),0)]):_vm._e()]),(_vm.shouldShow)?_c('div',{staticClass:"suggestions__underlay",on:{"click":_vm.close}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }